body {
  margin: 0;
  padding: 0;
  font-family: 'League Spartan';
}

.main-container {
  position: relative;
  /* background-image: url('images/main-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; */
  background-color: black;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  padding: 20px;
}

.logo {
  max-width: 200px;
  /* Adjust the width as needed */
}

.sub-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
}

.video-container {
  flex: 1;
  max-width: 600px;
  /* Adjust the max-width as needed */
  margin-right: 20px;
  margin-bottom: 20px;
  /* Add margin at the bottom for better spacing */
}

.video {
  width: 100%;
  max-height: 400px;
}

.bullet-points {
  flex: 1;
  max-width: auto;
  /* Adjust the max-width as needed */
}

.bullet-list {
  list-style: disc;
  padding-left: 20px;
  font-size: xx-large;
}

.bullet-point {
  margin-bottom: 10px;
}

.text-center {
  width: 80%;
  font-size: 50px;
  font-weight: bold;
}

.encapsulated-line {
  width: 40%;
  font-size: x-large;
  /* background-color: green; */
  background-image: linear-gradient(to bottom right, rgb(28, 237, 116) 0%, rgb(47, 210, 251) 100%);
  ;
  border: 1px solid green;
  border-radius: 30px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  color: black;
}

/* Mobile Responsive Styling */
@media (max-width: 768px) {
  .encapsulated-line {
    width: 90%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .sub-container {
    flex-direction: column;
    align-items: center;
  }

  .video-container {
    max-width: 100%;
    margin-right: 0;
  }

  .bullet-points {
    max-width: 100%;
  }
}
@media (max-width: 950px){
  .text-center {
    font-size: 28px;
  }

  .main-container{
    padding: 5px;
  }
}

@media (max-width: 350px) {
  .text-center {
    font-size: 24px;
  }

  .main-container {
    padding: 5px;
  }
}

#typewriter {
  /* font-family: monospace; */
  white-space: pre;
  position: absolute;
  /* top: 130px; */
  /* padding: 2px; */
  padding-left: 10px;
  /* color: #0CC0DF; */
  color: yellow;
  font-weight: bold;
}

.remainingText {
  padding-left: 320px;
}

.steps {
  background-color: rgb(0, 123, 255);
  padding: 50px;
  border-radius: 30px;
  color: white;
  margin-top: 100px;
}

.step-image {
  margin-top: -100px;
}

.steps-image {
  width: 100%;
}

.step-text-heading {
  background-color: white
  ;
  color: black;
  font-weight: bold;
  font-size: 28px;
  padding: 10px;
  border-radius: 20px;
  width: 120px;
  text-align: center;
}

.step-text-description{
  font-size: 24px;
}

.click-here-timer-main {
  background-color: black;
  padding: 50px;
  color: white;
}

.line-over-text {
  position: relative;
  font-size: 24px;
  color: white;
  /* Adjust the font weight as needed */
  text-align: center;
  /* Adjust the text alignment as needed */
}

/* Create the line above the text using a pseudo-element */
.line-over-text::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 3px;
  background-color: white;
  /* Adjust the color of the line */
  /* transform: translateY(-50%); */
  /* Center the line vertically */
}

.click-here-button {
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 50px;
  background-image: linear-gradient(to bottom right, rgb(28, 237, 116) 0%, rgb(47, 210, 251) 100%);
}

.payment-logos {
  width: auto;
  height: 40px;
}

.hurry-main {
  margin-top: 30px;
  background-color: rgb(33, 33, 33);
  padding: 15px;
  font-size: 28px;
  text-align: center;
}

#timer {
  text-align: center;
}

.timer-counter {
  font-size: 40px;
}

.commercial {
  border: 3px dotted white;
  border-radius: 20px;
  padding: 20px;
  font-size: 22px;
  text-align: center;
}

.articlesRows {
  margin-top: 100px;
}

.articles-image-div {
  width: 90%;
  padding: 20px;
  border: 1px solid lightblue;
  border-radius: 20px;
}

.articles-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.articles-heading-number {
  margin-top: 4px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background-color: #1a7598;
  color: white;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  padding-top: 5px;
}

.articles-heading-title {
  color: #1a7598;
  margin-left: 30px;
  font-size: xx-large;
}

.articles-top-para {
  font-weight: bold;
  margin-top: 20px;
  white-space: pre-wrap;
  font-size: large;
}

.articles-point-to-ponter {
  border-left: 2px solid blue;
  background-color: lightblue;
}

.articles-point-to-ponter-text {
  margin-top: 0px;
  padding: 10px;
}

.articles-point-to-ponter-image {
  width: 130px;
  height: 180px;
  margin-top: -30px;
  margin-left: 10px;
}

.articles-bullet-points-image {
  width: 20px;
  height: 20px;
}

.articles-bullet-points-text {
  margin-top: 0px;
}

.main-banner {
  /* background-image: url('https://img.freepik.com/free-vector/abstract-background-with-waves-glow-vector-illustration-light-background_1182-2531.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; */
  background-color: rgb(240, 240, 240);
}

.main-banner-para {
  font-size: 18px;
}

.main-banner-text{
  font-size: 24px;
}

.main-banner-tick .main-banner-item {
  position: relative;
  padding-left: 40px;
  margin-top: 8px;
  font-weight: bold;
  font-size: 20px;
  /* Adjust as needed */
}

.main-banner-tick .main-banner-item:before {
  content: "\2713";
  /* Unicode character for tick mark */
  position: absolute;
  left: 0;
  border: 1px solid;
  background-color: black;
  /* Add a border around the tick mark */
  width: 30px;
  /* Adjust dimensions as needed */
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 100%;
}

.main-banner-rating-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-left: 30px;
}

.main-banner-star {
  font-size: 24px;
  color: gold;
  margin-right: 5px;
}

.main-banner-rating-text {
  margin-left: 10px;
  margin-top: 5px;
  font-size: 14px;
}

.main-banner-unlock-offer {
  width: 100%;
  margin-top: -30px;
  border-radius: 50px;
  height: 60px;
  font-weight: bold;
  font-size: 28px;
  background-color: black;
}

.main-banner-arrow {
  border-radius: 100%;
}

.main-banner-image {
  width: 70%;
  padding: 50px;
}

.main-banner-image2 {
  width: 80%;
  padding: 20px;
}

.six-items-heading {
  color: #1a7598;
  text-align: center;
}

.six-items-col-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.six-items-image {
  max-width: 100px;
  margin-bottom: 10px;
}

.box-points-container {
  background-color: #000000;
  color: white;
}

.box-points {
  border: 2px solid white;
  border-radius: 10px;
  padding: 30px;
  width: 80%;
  margin-top: 40px;
  font-weight: bold;
  font-size: 24px;
}

.box-points-ticks:before {
  content: "\2713";
  /* Unicode character for tick mark */
  position: absolute;
  left: 0;
  top: 30%;
  border: 1px solid;
  background-color: white;
  /* Add a border around the tick mark */
  width: 30px;
  /* Adjust dimensions as needed */
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-weight: bold;
  font-size: 18px;
  border-radius: 100%;
}

.offer-banner {
  /* background-color: #1a7598; */
  background-color: white;
}

.offer-banner-left-image {
  width: 200px;
  height: 200px;
  transform: rotate(-20deg);
}

.offer-banner-right-image {
  width: 200px;
  height: 200px;
  transform: rotate(20deg);
}

.thirdBannerImages{
  width: 300%;
  height: auto;
}

.thirdBannerTextCol{
  display: flex;
  align-items: center;
  justify-content: center;
}

.thirdBannerText{
  text-align: center;
}

.questions{
  background-color: rgb(240, 240, 240);
  color: black
}

.question-container{
  width: 90%;
  border: 3px solid black;
  border-radius: 10px;
  padding: 0px;
  margin-top: 30px;
}

@media (width<900px) {
  .question-container {
    width: 100%;
    /* Adjusted width for smaller screens */
  }
}

.question-image {
  height: auto;
  width: 100%;
  object-fit: cover;
}

.question-text{
  white-space: pre-line; /* Preserve white spaces and line breaks */
  overflow-wrap: break-word; /* Allow word wrapping within the column */
  font-size: x-large;
}

.question-header{
  width: 100%;
  background-color: black;
  height: 60px;
  display: flex;
  align-items: center;
  color: white;
  padding-left: 16px;
}

.question-header-counter{
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: white;
  color: black;
  padding-left: 13px;
}

.question-header-text{
  margin-left: 20px;
  margin-top: -5px;
  font-weight: bold;
}

.two-rows{
  padding: 70px;
}

.two-rows-image{
  width: 60%;
  height: auto;
}

.two-rows-image-2{
  width: 100%;
  height: auto;
}

.two-rows-text{
  white-space: pre-line;
}